import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import ExternalLink from '@components/ExternalLink/ExternalLink';
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Verishop`}</h1>
    <div className={styles.workDetails}>
  Engineering team <br /> Santa Monica, CA &mdash; 2018&#8202;&ndash;&#8202;2020
    </div>
    <p>{`Joined a small team to create an e-commerce experience from scratch. I worked across the front and back-end engineering teams, focusing on user experience, growth/SEO, and internal tooling.`}</p>
    <aside>
  <hr />
    </aside>
    <h2>{`Technology`}</h2>
    <ul>
      <li parentName="ul">{`Frontend built using Typescript (React/Next.js)`}</li>
      <li parentName="ul">{`Back-end built using Typescript and GraphQL`}</li>
      <li parentName="ul">{`Largely "serverless" infrastructure running on AWS Lambda and DynamoDB`}</li>
      <li parentName="ul">{`Software architecture was heavily inspired by domain-driven design concepts`}</li>
    </ul>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><ExternalLink href="https://www.verishop.com" mdxType="ExternalLink">verishop.com</ExternalLink></li>
      <li parentName="ul"><ExternalLink href="https://medium.com/lightspeed-venture-partners/ecommerce-trek-ii-the-wrath-of-khan-5d12c8fb97a1" mdxType="ExternalLink">
  funding announcement [lightspeed.com]
        </ExternalLink></li>
    </ul>
    <Spacer height={24} mdxType="Spacer" />
    <aside>
      <hr></hr>
      <p>{`Prior to Verishop, Adam worked at `}<a parentName="p" {...{
          "href": "/startup/cadre"
        }}>{`Cadre`}</a>{`, a New York-based
startup providing easy access to real estate investments.`}</p>
    </aside>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      